var error = false;
function checkRequired($container){
    var inputs = $container.find(':input.required');
    $.each(inputs, function(key, input) {
        input = $(input);

        if (typeof input.attr('data-match') != 'undefined') {
            var match = $(input.attr('data-match')).val();

            if (match != input.val()) {
                error = input.attr('data-error');
                if (typeof error == 'undefined') {
                    error = "This fields must match";
                }

                showValidationError(input, error);
            }
        }
        
        if (typeof input.attr('data-check') != 'undefined'){
            var check = $(input.attr('data-check')).val();
            var data = {
                input: input.attr('name'),
                email: input.val(),
                form: input.parents('form').attr('id')
            };
            error = 'This email has already been used.';

            loadAsync('home/check-email', data);
        }


        if (typeof input.attr('data-min') != 'undefined'){
            var min = parseInt(input.attr('data-min'));
            if (input.val().length < min){
                error = 'Your password must be at least ' + min + ' characters long';
                showValidationError(input, error);
            }
        }

        if (typeof input.attr('data-email') != 'undefined'){
            var rule = /\S+@\S+\.\S+/;
            var email = input.val();
            if (!rule.test(email)) {
                error = 'Please enter a valid email address';
                showValidationError(input, error);
            }
        }


        if (input.val() == '') {
            error = input.attr('data-error');
            if (typeof error == 'undefined') {
                error = "This field is required";
            }
            showValidationError(input, error);
        }
    })

    if (error) {
        return false;
    }

    return true;
}

$(document).on('keyup change', '.has-error :input', function(e){
    var $this = $(this);

    if ($this.val() != '' && typeof $this.attr('data-stop') == 'undefined') {
        removeValidationError($this);
    }

    if (typeof $this.attr('data-match') != 'undefined') {
        var $match = $($this.attr('data-match'));

        if ($match.val() == $this.val() || typeof $this.attr('data-skip') != 'undefined') {
            removeValidationError($this);
            removeValidationError($match);
        } else {
            error = $this.attr('data-error');
            if (typeof error == 'undefined') {
                error = "This fields must match";
            }
            showValidationError($this, error);
        }
    }
});

$(document).on('keyup change', '.step3 :input', function () {
    var input = $(this);
    if (typeof input.attr('data-min') != 'undefined'){
        var min = parseInt(input.attr('data-min'));
        if (input.val().length < min){
            error = 'Your password must be at least ' + min + ' characters long';
            showValidationError(input, error);
            return false;
        }
    }
})


function showValidationError($this, error){
    if ($this.parents('.form-group').find('.help-block').length == 0 ) {
        $this.parents('.form-group').addClass('has-error').append('<span class="help-block"><strong>' + error + '</strong></span>');
    }
};

function removeValidationError($this){
    error = false;
    $this.parents('.form-group').removeClass('has-error');
    $this.parents('.form-group').find('.help-block').remove();
};

/**
 * add email existing error
 * @param data
 */
function addError(data) {
    if (data.checkStatus == 'success'){
        $('#' + data.checkForm).submit();
    }
    if (typeof data.checkError != 'undefined') {
        var input = $('[name=' + data.checkInput + ']');
        var error = data.checkError;
        showValidationError(input, error);
    }
}





