$(function () {

    
    
});

var registrationStep = 1;
$(document).on('click', '.next', function(){

    if (checkRequired($('.step'+ registrationStep))) {
        registrationStep++;
        $('.registration-step').hide();
        /**
        *  change title and hide registration description when registration step changed
        **/
        switch (registrationStep) {
            case 2:
                $("#registrationTitle").text("Welcome! You're a step away from successfully joining...");
                $('.register-description').hide();
                break;
            case 3:
                $("#registrationTitle").text("Mobile number");
                break;
            case 4:
                $("#registrationTitle").text("Set your password");
                break;
            case 5:
                $("#registrationTitle").text("Set your E-mail");
                break;
            default:
                // do nothing
                break;
        }
        /**
         * Hide login column, and add registration column full width
         * Hide "Post info" title
         * Script for modal registration step 2+
         */
        if ($(this).attr('id') === 'modalRegistrationButton') {
                $('#loginCol').hide();
                $('#registrationCol').removeClass("col-md-6");
                $('#title-post-info').hide();
        }
        $('.step'+ registrationStep).show();
    }

});

$(document).on('click', '.skip-phone', function () {
    registrationStep++;

    $('.registration-step').hide();

    switch (registrationStep) {
        case 2:
            $("#registrationTitle").text("Welcome! You're a step away from successfully joining...");
            $('.register-description').hide();
            break;
        case 3:
            $("#registrationTitle").text("Mobile number");
            break;
        case 4:
            $("#registrationTitle").text("Set your password");
            break;
        case 5:
            $("#registrationTitle").text("Set your E-mail");
            initGoogleRecaptcha();
            break;
        default:
            // do nothing
            break;
    }

    $('.step'+ registrationStep).show();
});

/**
 * register user
 */
$(document).on('submit', '#signupForm', function(e){
    e.preventDefault();
    $(this).find('.js-finish-registration').addClass('registering').text('Registering').attr('disabled', true);
    loadAsync('register', $(this).serialize());
});

/**
 * register user from post
 */
$(document).on('submit', '#quickSignupForm', function(e){
    e.preventDefault();
    loadAsync('quick-register', $(this).serialize());
});

/**
 * change file uploading message in registration page
 */
$(document).on('change', '#uploadSupportingDocuments input', function () {
    $(this).parent().addClass('hidden');
    $('.upload-document-container').append('<span class="green">Supporting document has been uploaded.</span>');
});

/**
 * submit expert register form
 */
$(document).on('submit', '#expertRegisterForm', function (event) {
    event.preventDefault();
    var data = new FormData($(this)[0]);
    data.append('file[]', $('#proof_doc')[0].files[0]);

    loadAsyncWithFile('register/' + $(this).attr('data-type') + '/store', data);
});

/**
 * redirects the user to register given the type selected
 */
$(document).on('click', '#selectExpertType', function () {
    var type = $('.expertType option:selected').val();

    if (typeof type != 'undefined' && type != ''){
        window.location.href = type;
    }
});


/**
 * set focus on the input
 */
function setFocus() {
    $('.help-block').first().prev('label').find('input').focus();
}

$(document).on('change', '#phone_country', function () {
    var selected = $('#phone_country option:selected').val();
    $('[name=phone_code]').val(selected);
});


$(document).on('change', '#changeRegisterType', function () {
    window.location.href = $(this).find('option:selected').val();
});

function displayExpertValidationErrors() {
    setFocus();
    initGoogleRecaptcha();
}

function enableButton() {
    $('.js-finish-registration').removeClass('registering').text('Finish').removeAttr('disabled');
}