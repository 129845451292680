/**
 * Init bootstrap tooltip
 */
function initTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
}

/**
 * Add another hospital form
 */
$(document).on('click', '#addAnotherHospital', function () {
    loadAsync('experts/add-hospital', {});
});