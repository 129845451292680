//$(function () {

    /**
     * var recentPosts may be printed in any template. It has to identify recent posts type to get them from backend 
     */
    if (typeof recentPosts != 'undefined') {
        getRecentPosts(recentPostsPage);
        setInterval(function(){getRecentPosts(recentPostsPage)}, 50000);
    }

//});


function getRecentPosts(page) {
    page = parseInt(page) > 0 ? parseInt(page) : 0;
    loadAsync('api/get/recent-posts', {category : recentPosts, page: page});
}