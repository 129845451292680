var commentsShown = 2;

$(function () {
    $('#birthDate').datepicker({
        format: "yyyy-mm-dd"
    });

    if (typeof connectionsPage != 'undefined') {
        getConnections(connectionsPage);

        /**
         * Load doctors. And update them every 50000
         */
        setInterval(function(){getConnections(connectionsPage)}, 50000);
    }

    if (typeof profileConnectionsPage != 'undefined') {
        getProfileConnections(profileConnectionsPage);

        /**
         * Load connections. And update them every 50000
         */
        setInterval(function(){getProfileConnections(profileConnectionsPage)}, 50000);
    }
});
/**
 * recommend user
 */
$('#likeUser').on('click', function () {
    var link = $(this);
    var data = {
        whom_liked : link.attr('data-user')
    };
    loadAsync('profile/recommend', data);
});

/**
 * action after successfull recommendation of user
 */
function recommendUser(){
    var link = $('#likeUser');
    var likesCount = $('#likesCount');
    if (typeof link.find('.likeLabel').attr('data-doctor') != 'undefined') {
        link.find('.likeLabel').text('Thank');
    } else {
        link.find('.likeLabel').text('Recommend');
    }
    likesCount.text(parseInt(likesCount.text()) - 1);
}

/**
 * action after successfull unrecommendation of user
 */
function unRecommendUser(){
    var link = $('#likeUser');
    var likesCount = $('#likesCount');
    console.log(link.find('.likeLabel').attr('data-doctor'));
    if (typeof link.find('.likeLabel').attr('data-doctor') != 'undefined') {
        link.find('.likeLabel').text('Thanked');
    } else {
        link.find('.likeLabel').text('UnRecommend');
    }
    likesCount.text(parseInt(likesCount.text()) + 1);
}

/**
 * send reccomendation message
 */
$('#recommendationForm').on('submit', function (event) {
    event.preventDefault();
    var form = $(this);
    var link = $('#likeUser');
    var likesCount = $('#likesCount');
    var data = {
        recommendation : form.find('textarea').val(),
        whom_liked : link.attr('data-user')
    };
    loadAsync('profile/addRecommendation', data);
});

/**
 * hide recommendation form after sending the image
 */
function hideRecommendationForm(){
    var form = $('#recommendationForm');
    var link = $('#likeUser');
    var likesCount = $('#likesCount');

    link.find('.likeLabel').text('UnRecommend');
    likesCount.text(parseInt($('#likesCount').text()) + 1);
    form.slideUp("slow");
}

/**
 * cancel pending invitation
 */
function cancelInvitation(){
    var data = {
        sender_id : $('#connectionsTableActionButton').attr('data-sender'),
        recipient_id : $('#connectionsTableActionButton').attr('data-recipient')
    };

    loadAsync('profile/cancelInvitation', data);
};

$(document).on('click', '.cancelInvitation', function () {
    var $this = $(this);
    swal({
            title: "Are you sure?",
            text: "Are you really want to cancel invitation?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, cancel!",
            cancelButtonText: "No!",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function(isConfirm){
            if (isConfirm) {
                var data = {
                    sender_id : $this.attr('data-sender'),
                    recipient_id : $this.attr('data-recipient')
                };

                loadAsync('profile/cancelInvitation', data);
            }
        });
});

/**
 * accept invitations
 */
function acceptInvitation(){
    var data = {
        sender_id : $('#connectionsTableSecondActionButton').attr('data-sender'),
        recipient_id : $('#connectionsTableSecondActionButton').attr('data-recipient')
    };

    loadAsync('profile/acceptInvitation', data);
};

/**
 * open decline messages modal form
 * @param senderId
 * @param recipientId
 */
function openDeclineModal(senderId, recipientId){
    $('[name=sender_id]').val(senderId);
    $('[name=recipient_id]').val(recipientId);
};

/**
 * decline invitation
 */
$('#declineForm').on('submit', function(e){
    e.preventDefault();
    var data = $('#declineForm').find('form').serialize();

    loadAsync('profile/declineInvitation', data);
    $('#declineForm').modal('close');
});

/**
 * get user connections list
 * @param page
 */
function getConnections(page){
    page = parseInt(page) > 0 ? parseInt(page) : 0;
    var user = $('#currentUser').val();
    loadAsync('profile/getConnectionsList', {
        page: page,
        user: user
    });
};

function unsubscribe(category_id) {
    swal({
            title: "Are you sure?",
            text: "Are you really want to unsubscribe?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, unsubscribe!",
            cancelButtonText: "No, cancel!",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function(isConfirm){
            if (isConfirm) {
                loadAsync('profile/unsubscribe', {category_id : category_id});
            }
        });
}

function unsubscribeQuestion(id) {
    swal({
            title: "Are you sure?",
            text: "Are you really want to unsubscribe?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, unsubscribe!",
            cancelButtonText: "No, cancel!",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function(isConfirm){
            if (isConfirm) {
                loadAsync('profile/unsubscribeQuestion', {id : id});
            }
        });
}

/**
 * load more user recommendations
 */
function loadMoreComments(user_id) {
    var data = {
        user_id: user_id,
        offset: commentsShown
    };
    loadAsync('profile/more-comments', data);
    commentsShown += 3;
}

function hideMoreButton(data) {
    if (commentsShown >= data.count) {
        $('.js-show-more-comments').addClass('hidden');
    }
}

/**
 * Display profile image preview
 * @param coords
 */
function showPreview(coords)
{
    var rx = 80 / coords.w;
    var ry = 80 / coords.h;

    var width = $('#profilePicturePreview').width();
    var height = $('#profilePicturePreview').height();

    $('#imageCropCoords').val(JSON.stringify(coords));

    $('#previewImage').removeClass('hidden');
    $('#previewImage').css({
        width: Math.round(rx * width) + 'px',
        height: Math.round(ry * height) + 'px',
        marginLeft: '-' + Math.round(rx * coords.x) + 'px',
        marginTop: '-' + Math.round(ry * coords.y) + 'px'
    });
}

/**
 * init jcrop plugin
 */
function initJcrop(){
    $('#profilePicturePreview').Jcrop({
        setSelect:   [ 50, 50, 400, 300 ],
        onSelect: showPreview,
        onChange: showPreview,
        aspectRatio: 1,
        minSize: [200, 200]
    });
};

/**
 * upload profile picture
 */
$(document).on('change', '#profilePicture', function (event) {
    var $this = $(this);
    var file = event.target.files; // FileList object
    var f = file[0];
    if (!f.type.match('image.*')) {
        swal({
                title: "Error",
                text: "Image only please....",
                type: "warning",
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ok",
        });
        return false;
    }
    if (f.size > 4 * 1024 * 1024 ) {
        swal({
            title: "Error",
            text: "Maximum image size is 4 Mb",
            type: "warning",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok",
        });
        return false;
    }
    var reader = new FileReader();
    reader.onload = (function(theFile) {
        return function(e) {
            $('#profilePicturePreview').attr('src', e.target.result);
            $('#previewImage').attr('src', e.target.result);
            $('#editPicture').addClass('hidden');
            $('#cropPicture').removeClass('hidden');
            initJcrop();
        };
    })(f);
    reader.readAsDataURL(f);
});

/**
 * Save profile image
 */
$(document).on('click', '#saveProfileImage', function () {
    var file = $('#profilePicturePreview').attr('src');
    var coords = $('#imageCropCoords').val();
    var width = $('#profilePicturePreview').width();
    var height = $('#profilePicturePreview').height();

    $(this).addClass('saving').text('Saving...');
    if (typeof token != 'undefined') {
        loadAsync('save-profile-image', {file: file, coords: coords, dimensions: {w: width, h: height}, token: token});
    } else {
        loadAsync('save-profile-image', {file: file, coords: coords, dimensions: {w: width, h: height}});
    }
});

/**
 * Send close post request
 */
$(document).on('click', '#closePost', function () {
   var data = {
       post_id: $(this).attr('data-post')
   };

   loadAsync('posts/close-post', data)
});

/**
 * Send open post request
 */
$(document).on('click', '#openPost', function () {
   var data = {
       post_id: $(this).attr('data-post')
   };

   loadAsync('posts/open-post', data)
});

$(document).on('click', '#addHospitalEdit', function () {
    loadAsync('profile/add-hospital', {})
})

$(document).on('click', '.removeHospital', function () {
    var hospiytalId = $(this).attr('data-hospital');
    $('.singleHospitalContainer[data-hospital=' + hospiytalId + ']').remove();
});

$(document).on('click', '.js-delete-recommendation', function () {
    var $this = $(this);
    swal({
            title: "Are you sure?",
            text: "Do you really want to remove this recommendation?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it!",
            cancelButtonText: "No, cancel!",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function(isConfirm){
            if (isConfirm) {
                loadAsync('profile/remove-recommendation', {recommendation_id: $this.attr('data-recommendation')});
            }
        });
});

function removeRecommedation(data) {
    $('.js-recommendation[data-recommendation=' + data.rmdId + ']').remove();
}

