/**
 * LoadAsync function - function for sending and receiving parameters and messages from server.
 * Function provides opportunity to put received data in container, by any types of inserting (append, prepend, replacement)
 * @param controller - main part of URL
 * @param action - optional parameters of URL, will be merged with controller parameter, using the separator "/"
 * @param params - optional parameter will be sent as an object ( like as - var params = {param1: "value" ..etc..}; )
 * For @param - params object is added special "_token" of page by default
 * @returns {boolean}
 */
function loadAsync(action, params, doNotShowLoader, method)
{
    if(typeof method == 'undefined') method = 'post';
    var postponed = true;
    setTimeout(function(){
        if(postponed == true && typeof doNotShowLoader == 'undefined') {
            showLoader();

            setTimeout(function(){
                if(postponed == true) {
                    hideLoader();
                    // swal('Something went wrong', 'Something went wrong, please try again in few minutes, or contact support', 'error');
                }
            }, 20000)
        }

    }, 700);

    var link =  "/" + action;

    if(params.length == 0){
        params = {};
    }

    params._token = $('meta[name="csrf-token"]').attr('content'); 
    params.c = link;

    var dataType = (typeof dataType == 'undefined') ? 'json' : 'html';

    $.ajax({
        type: method,
        dataType: dataType,
        url: link,
        data: params,
        success: function(data){

            if(typeof data.redirect != 'undefined') window.location.href = data.redirect;

            postponed = false;
            if (typeof doNotShowLoader == 'undefined' || !doNotShowLoader) {
                hideLoader();
            }

            if(typeof data.timeout == 'undefined') data.timeout = 0;


            if(typeof data.reload != 'undefined') window.location.reload();

            /**
             * Modal response
             * html - modal body
             * modal - modal id
             */
            if(typeof data.modal != 'undefined') {
                $(data.modal).remove();
                $('body').append(data.modal);
                $(data.modal).modal('show');
                //setTimeout(initJSmisc(), 500);
            }

            if(typeof data.dismiss != 'undefined') {
                $('.modal').modal('hide');
            }


            /**
             * Response for sweet alert
             * acceptable
             * alertHeader
             * alertType
             * alert (message text)
             */
            if(typeof data.alert != 'undefined') {
                swal({
                    title : (typeof data.alertHeader != 'undefined') ? data.alertHeader : 'success',
                    text : data.alert,
                    type : (typeof data.alertType != 'undefined') ? data.alertType : 'success',
                }, function (isConfirm) {
                    if (isConfirm){
                        if(typeof data.redirectAlert != 'undefined') window.location.href = data.redirectAlert;
                        if(typeof data.reloadAlert != 'undefined') window.location.reload();
                    }
                }
                    
                )
            }


            setTimeout(function() {

                if (typeof data.container != 'undefined' && typeof data.html != 'undefined') {
                    var action = (typeof data.type == 'undefined') ? 'html' : data.type;

                    if (action == 'after')
                        $(data.container).after(data.html);
                    else if (action == 'html')
                        $(data.container).html(data.html);
                    else if (action == 'append')
                        $(data.container).append(data.html);
                    else
                        $(data.container).append(data.html);

                    //initJSmisc(data.container);
                }

                if(typeof (data.action) != 'undefined'){
                    var callback = data.action;
                    window[callback](data);
                }

            }, data.timeout);

        }, error: function(jqXHR, textStatus, errorThrown){
            //alert(errorThrown);
            postponed = false;
            hideLoader();
        }
    });

}

function loadAsyncWithFile(action, params, doNotShowLoader, method)
{
    if(typeof method == 'undefined') method = 'post';
    var postponed = true;
    setTimeout(function(){
        if(postponed == true && typeof doNotShowLoader == 'undefined') {
            showLoader();

            setTimeout(function(){
                if(postponed == true) {
                    hideLoader();
                    // swal('Something went wrong', 'Something went wrong, please try again in few minutes, or contact support', 'error');
                }
            }, 20000)
        }

    }, 700);

    var link =  "/" + action;

    if(params.length == 0){
        params = {};
    }

    params._token = $('meta[name="csrf-token"]').attr('content');
    params.c = link;

    var dataType = (typeof dataType == 'undefined') ? 'json' : 'html';

    $.ajax({
        type: method,
        dataType: dataType,
        url: link,
        data: params,
        processData: false,
        contentType: false,
        success: function(data){

            if(typeof data.redirect != 'undefined') window.location.href = data.redirect;

            postponed = false;
            if (typeof doNotShowLoader == 'undefined' || !doNotShowLoader) {
                hideLoader();
            }

            if(typeof data.timeout == 'undefined') data.timeout = 0;


            if(typeof data.reload != 'undefined') window.location.reload();

            /**
             * Modal response
             * html - modal body
             * modal - modal id
             */
            if(typeof data.modal != 'undefined') {
                $(data.modal).remove();
                $('body').append(data.modal);
                $(data.modal).modal('show');
                //setTimeout(initJSmisc(), 500);
            }

            if(typeof data.dismiss != 'undefined') {
                $('.modal').modal('hide');
            }


            /**
             * Response for sweet alert
             * acceptable
             * alertHeader
             * alertType
             * alert (message text)
             */
            if(typeof data.alert != 'undefined') {
                swal({
                    title : (typeof data.alertHeader != 'undefined') ? data.alertHeader : 'success',
                    text : data.alert,
                    type : (typeof data.alertType != 'undefined') ? data.alertType : 'success',
                }, function (isConfirm) {
                    if (isConfirm){
                        if(typeof data.redirectAlert != 'undefined') window.location.href = data.redirectAlert;
                        if(typeof data.reloadAlert != 'undefined') window.location.reload();
                    }
                }

                )
            }


            setTimeout(function() {

                if (typeof data.container != 'undefined' && typeof data.html != 'undefined') {
                    var action = (typeof data.type == 'undefined') ? 'html' : data.type;

                    if (action == 'after')
                        $(data.container).after(data.html);
                    else if (action == 'html')
                        $(data.container).html(data.html);
                    else if (action == 'append')
                        $(data.container).append(data.html);
                    else
                        $(data.container).append(data.html);

                    //initJSmisc(data.container);
                }

                if(typeof (data.action) != 'undefined'){
                    var callback = data.action;
                    window[callback](data);
                }

            }, data.timeout);

        }, error: function(jqXHR, textStatus, errorThrown){
            //alert(errorThrown);
            postponed = false;
            hideLoader();
        }
    });

}

function showLoader() {
    $('.loader-container').fadeIn();
}

function hideLoader() {
    $('.loader-container').fadeOut();
}


/**
 * Main form handler.
 * @param action. API action which will be performed on submit
 * @param $this
 */
function saveForm($this){

    event.preventDefault();

    var action = $this.attr('action');
    var post = {};

    $.each($($this).serializeArray(), function(i, field) {
        if(field.name.indexOf('[]') > 0) {
            if(typeof post[field.name] == 'undefined') post[field.name] = [];
            post[field.name].push(field.value);
        } else {
            post[field.name] = field.value;
        }
    });


    loadAsync(action, post);
}